import React from 'react';
import PropTypes from 'prop-types';
import Carousel from 'nuka-carousel';

import styled from 'styled-components';

import HeroImage from '../../slices/HeroImage';
import HeroVideo from '../../slices/HeroVideo';

const Root = styled.div`
  position: relative;
  width: 100%;
`;

/**
 * Maybe there should be a fixed item that can be passed as the Optional-page-title item? If not passed use h1 for first item
 */

function HeroCarousel({ items, big }) {
  return (
    <Root>
      <Carousel
        transitionMode="fade"
        speed={710}
        autoplay
        autoplayInterval={3500}
        wrapAround
        defaultControlsConfig={{
          // nextButtonClassName: PropTypes.string,
          nextButtonStyle: {
            display: 'none',
          },
          // nextButtonText: PropTypes.string,
          // prevButtonClassName: PropTypes.string,
          prevButtonStyle: {
            display: 'none',
          },
          // prevButtonText: PropTypes.string,
          // pagingDotsContainerClassName: PropTypes.string,
          // pagingDotsClassName: PropTypes.string,
          pagingDotsStyle: {
            fill: '#FFF',
          },
        }}
      >
        {items &&
          items.map((slice, index) => {
            switch (slice.type) {
              case 'image_item':
                return (
                  <HeroImage
                    key={index} // eslint-disable-line react/no-array-index-key
                    data={slice}
                    big={big}
                    pageTitle={index === 0}
                  />
                );
              case 'video_item':
                return (
                  <HeroVideo
                    key={index} // eslint-disable-line react/no-array-index-key
                    data={slice}
                    big={big}
                    pageTitle={index === 0}
                  />
                );
              default:
                return null;
            }
          })}
      </Carousel>
    </Root>
  );
}

HeroCarousel.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object),
  big: PropTypes.bool,
};

HeroCarousel.defaultProps = {
  items: [],
  big: false,
};

export default HeroCarousel;
