import React from 'react';
import PropTypes from 'prop-types';
import { RichText } from 'prismic-reactjs';
import { linkResolver } from '@theprepgroup/gatsby-source-prismic-graphql';
import styled from 'styled-components';

import Hero from '../../components/Hero';
import Button from '../../components/Button';

// import LinkUtil from '../../utils/link';

const Root = styled.div`
  position: relative;
  width: 100%;
`;

// function debounce(fn, ms) {
//   let timer;
//   return () => {
//     clearTimeout(timer);
//     timer = setTimeout(() => {
//       timer = null;
//       fn.apply(this, arguments);
//     }, ms);
//   };
// }

function HeroVideo({ data, pageTitle }) {
  // const [dimensions, setDimensions] = useState({
  //   height: window.innerHeight,
  //   width: window.innerWidth,
  // });
  // useEffect(() => {
  //   const debouncedHandleResize = debounce(function handleResize() {
  //     setDimensions({
  //       height: window.innerHeight,
  //       width: window.innerWidth,
  //     });
  //   }, 1000);

  //   window.addEventListener('resize', debouncedHandleResize);
  // });

  const videoProps = {
    // src: [
    //   {
    //     src: 'http://download.blender.org/peach/trailer/trailer_400p.ogg',
    //     type: 'video/ogg',
    //   },
    //   {
    //     src: 'http://clips.vorwaerts-gmbh.de/VfE_html5.mp4',
    //     type: 'video/mp4',
    //   },
    // ],
    // poster:
    //   'http://il6.picdn.net/shutterstock/videos/3548084/thumb/1.jpg?i10c=img.resize(height:160)',
    // containerWidth: dimensions.width && dimensions.width,
    // containerHeight: dimensions.height && dimensions.height,
  };

  if (data.primary.video) {
    videoProps.src = [
      {
        src: data.primary.video.url,
        type: 'video/mp4',
      },
    ];
    videoProps.poster = data.primary.image?.url;
  }

  return (
    <Root>
      <Hero
        preHeading={
          data.primary.pre_title && RichText.asText(data.primary.pre_title)
        }
        heading={RichText.asText(data.primary.title)}
        image={data.primary.imageSharp}
        actions={
          data.primary.link && (
            <Button link={data.primary.link} variant="hollow">
              {data.primary.link_label}
            </Button>
          )
        }
        big
        pageTitle={pageTitle}
        videoProps={videoProps}
      >
        {data.primary.text && (
          <RichText render={data.primary.text} linkResolver={linkResolver} />
        )}
      </Hero>
    </Root>
  );
}

HeroVideo.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
  pageTitle: PropTypes.bool,
};

HeroVideo.defaultProps = {
  pageTitle: false,
};

export default HeroVideo;
